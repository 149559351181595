import React, { useState, useEffect, useRef } from 'react'
import Header from './components/Header'
import { Contactform } from './components/Contactform'
import { Footer } from './components/Footer'
import Slider from 'react-slick';
import img02 from './images/poster-03.webp';
import { Form } from './components/Form';
import Icon01 from './images/ad04.webp'
import Icon02 from './images/unlimited-data.webp'
import Icon03 from './images/unlimited-call.webp'
import Icon04 from './images/ott-subscribtion.webp'
import Icon05 from './images/all-in-one.webp'
import { Link } from 'react-router-dom'
import { Ottslider } from './components/Ottslider';
import { Effectarrow } from './components/Effectarrow';
import axios from 'axios';

export const Netplushd = () => {

    var decodeHTML = function (html) {
        var txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };

    function createMarkup(content) {
        return { __html: decodeHTML(content) };
    }

    const [pagedata, setPagedata] = useState([]);
    useEffect(() => {
        let mounted = true;
        const loadData = async () => {
            const response = await axios.get(window.weburl + `api/plans.php?catID=19`)
            if (mounted) {
                setPagedata(response.data);
            }
        }

        loadData();

        return () => {
            mounted = false;
        };
    }, []);

    console.log(pagedata);
    if (pagedata.length === 0) {
        console.log('no data');
        // return null;
    }

    var settings = {
        dots: true,
        navigation: true,
        loop: true,
        fade: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: false,
        speed: 300,
        autoplaySpeed: 5000,
        arrows: false,
        cssEase: "linear"
    };
    return (
        <>
            <Header />
            <section id='banner' className='p-0 grey-color internal ' data-aos="fade-in" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                <div className='banner'>
                    <div className='leftarrow white'>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow white'>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='home-slider'>
                                    <Slider {...settings}>
                                        <div>
                                            <div className='sliderrow'>
                                                <div className='slideimg'>
                                                    <img src={img02} alt='Banner Netplus' />
                                                </div>
                                                <div className='slidecont'>
                                                    <div className='circle01'>
                                                        <div className='mngcircle'>
                                                            <div className='circlimg'>
                                                                <h2>Big value bundle <br />starting <span className='finr'>699/-</span></h2>
                                                                <h4>Live HD Tv +Unlimited data</h4>
                                                            </div>
                                                            <div className='booknow justify-content-start'>
                                                                <div className="wrap "><Link className="btn-11" to='/book-now/ZGI5WEIzbGhPb1F3M3BUckdMbFRudz09'><span>Get Now Connection</span></Link></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='filter' className='grey-color'>
                <Form cat="19" />
            </section>
            <section id='hdbundle'>
                <div className='hdbundle'>
                    <div className='leftarrow '>
                        <Effectarrow />
                    </div>
                    <div className='rightarrow'>
                        <Effectarrow />
                    </div>
                    <div className='container'>
                        <div className='row'>
                            <div className='web-container'>
                                <div className='gradienticons'>
                                    <ul>
                                        <li><span><img src={Icon01} alt='' /></span><p>Live HD TV</p></li>
                                        <li><span><img src={Icon02} alt='' /></span><p>Unlimited Data</p></li>
                                        <li><span><img src={Icon03} alt='' /></span><p>Free Voice Calls</p></li>
                                        <li><span><img src={Icon04} alt='' /></span><p>Free OTT Subscription</p></li>
                                        <li><span><img src={Icon05} alt='' /></span><p>All in one plan</p></li>
                                    </ul>
                                </div>
                                <div className='title'>
                                    <span>HD BUNDLE</span>
                                    <h3>Save more with big combos</h3>
                                    <h4>Broadband + HD Cable Plans</h4>
                                </div>
                                {pagedata.data ?
                                    <div className='bundlestn'>

                                        {pagedata.data.map(getData => {
                                            const newValues = getData.features.split('|_');
                                            return <div className='bundlebox'>
                                                <h4><i className='finr'></i>{getData.price}</h4>

                                                {getData.features ?
                                                    <ul>
                                                        {newValues.map(featuresValue => (
                                                            <>
                                                                {featuresValue ?
                                                                    <li dangerouslySetInnerHTML={createMarkup(featuresValue)}></li>
                                                                    : null}
                                                            </>
                                                        ))}
                                                    </ul>
                                                    : null}
                                               {getData.icon_values ? (
    <div className='entertainment'>
        <Ottslider geticonValues={getData.icon_values} />
    </div>
) : null}

                                                <div className='btn-11'>
                                                    <Link to={'/book-now/' + getData.plan_id}><span>Get Now</span></Link>
                                                </div>
                                            </div>
                                        })}





                                    </div>
                                    : null}

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id='packs'>
                <div className='packs coreadvantages'>
                    <div className='container'>
                        <div className='row'>
                            <div className='planbanner'>
                                <div className='packstn'>
                                    <div className='squarecont' data-aos="fade-up" data-aos-offset="200" data-aos-duration="600" data-aos-once="true" data-aos-easing="ease-in-sine">
                                        <ul>
                                            <li><span>Pay for <strong>3</strong> months & Get Free</span>
                                                <ul>
                                                    <li><strong>HD</strong> Box	</li>
                                                    <li><strong>Wi-fi</strong> Modem</li>
                                                    <li><strong>Zero</strong> Installation Charges</li>
                                                </ul>
                                            </li>
                                            <li><span>Pay for <strong>5</strong> months & Get <strong>1</strong> Month Free</span></li>
                                            <li><span>Pay for <strong>10</strong> months & Get <strong>2</strong> Months Free</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <Contactform /> */}
            <section id='filter' className='grey-color p-50'>
                <div className='title'>
                    <h3>Get your Connection Now!</h3>
                </div>
                <Form cat='cp' />
            </section>
            <Footer />
        </>
    )
}
